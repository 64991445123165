<template>
  <div class="tablebody">
    <div class="info">
        <b-btn class="paycount" size="sm" v-on:click="updatedata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid>
      <b-row>
        <b-col md="3">
          <b-input-group class="my-1">
            <b-form-input v-model="requisite" placeholder="Реквизит" size="sm" style="background-color: #c6ced4" />
            <b-input-group-append>
              <b-btn size="sm" :disabled="!requisite" @click="requisite = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="my-1">
            <b-form-input v-model="guid" placeholder="GUID" size="sm" style="background-color: #c6ced4" />
            <b-input-group-append>
              <b-btn size="sm" :disabled="!guid" @click="guid = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="3">
          <b-input-group class="my-1">
            <b-form-select v-model="select1" :options="selectList1" size="sm" style="background-color: #c6ced4" >
              <template slot="first">
                <option :value="null" >{{firstList[0]}}</option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!select1" @click="select1 = null" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="my-1">
            <b-form-select v-model="select2" :options="filterList(selectList2, 'dealerid', dealer)" size="sm" style="background-color: #c6ced4" >
              <template slot="first">
                <option :value="null" >{{firstList[1]}}</option>
              </template>
            </b-form-select>
            <b-input-group-append>
              <b-btn size="sm" :disabled="!select2" @click="select2 = null" ><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="3">
          <b-form-input class="my-1" v-model="startDate" type="date"  size="sm" id="type-date" style="background-color: #c6ced4"></b-form-input>
          <b-form-input class="my-1" v-model="endDate" type="date"  size="sm" id="type-date" style="background-color: #c6ced4"></b-form-input>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
              stacked="md"
              table-variant="dark"
              style="color: #ccc"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @row-clicked="expandAdditionalInfo"
      >
        <template slot="row-details" slot-scope="row">
          <b-card name="bcard" bg-variant="dark" text-variant="secondary">
            <ul>
              <li v-for="(value, key) in filterItem(row.item)" :key="key" >{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>
      <infinite-loading @infinite="querytabledata" ref="infiniteLoading">
        <div slot="no-more">Больше нет записей</div>
        <div slot="no-results"></div>
      </infinite-loading>
    </b-container>
</div>
</template>

<script>
import axios from 'axios';
import moment from 'dayjs';
import config from '../_services/config.json';

export default {
  name: 'Logs',
  data: () =>  ({
    items: [],
    fields: [],
    selectList:[],
    selectList1: [],
    selectList2: [],
    select1:null,
    select2:null,
    selectedStatus: '1',
    optionsStatus: [
      { text: 'Все', value: '1' },
      { text: 'Успешные', value: '0' },
      { text: 'Не успешные', value: '-1' }
    ],
    selectedPeriod: 'today',
    optionsPeriod: [
      { text: 'Сегодня', value: 'today' },
      { text: 'Вчера', value: 'yesterday' },
      { text: 'Месяц', value: 'month' }
    ],
    details: true,
    detailsorbill: [
      { text: 'Детали платежа', value: true },
      { text: 'Печать чека', value: false }
    ],
    perPage: 50,
    totalRows: 0,
    totalsum: 0,
    totalComission: 0,
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    endTime: '235959',
    guid: '',
    requisite: '',
    billnum: '',
    dealer: null,
    terminal: null,
    supplier: null,
    service: null,
    servicetype: null,
    expand: false,
    page:0,
    type: [],
    firstList: [],
  }),
  template: '',
  computed: {
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    isDealer () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Дилер'
      else return false
    }
  },
  watch: {
    selectedPeriod: function () {
      if (this.selectedPeriod==='yesterday') {
        this.startDate=moment().add(-1,'days').format('YYYY-MM-DD')
        this.endDate=moment().add(-1,'days').format('YYYY-MM-DD')
        this.endTime='235959'
      }
      else if (this.selectedPeriod==='today') {
        this.startDate=moment().format('YYYY-MM-DD')
        this.endDate=moment().format('YYYY-MM-DD')
      }
      else {
        this.startDate=moment().date(1).format('YYYY-MM-DD')
        this.endDate=moment().format('YYYY-MM-DD')
        this.endTime='235959'
      }
    },
    type: function () {
      this.selectLists()
    },
    select1: function () {
      this.setqueryparam (0,this.select1)
    },
    select2: function () {
      this.setqueryparam (1,this.select2)
    },
  },
  methods: {
    setqueryparam (i,id) {
      switch(this.selectList[i].idType) {
        case 'terminalId': this.terminal=id; break;
        case 'serviceType': this.servicetype=id;
      }
    },
    updatedata () {
        this.page=0;
        this.items=[];
        this.$refs.infiniteLoading.stateChanger.reset();
    },
    filterItem (item) {
      return Object.fromEntries(Object.entries(item).filter(([key]) => !['_showDetails','session_guid'].includes(key)))
    },
    filterList (items,property,value) {
      if (value&&value!='')
        return items.filter(v => v[property] == value);
      else return items;
    },
    expandAdditionalInfo(row) {
      row._showDetails = !row._showDetails;
    },
    querytabledata ($state) {
      if (this.fields===[]) this.tablefields();
        axios.post(`${config.apiUrl}/query/getdata`, { DB: 'logDB', requisite: this.requisite,startDate: this.startDate+'000000', endDate: this.endDate+this.endTime,
                                                      guid: this.guid, terminalId: this.terminal, serviceType: this.servicetype, infoMessage: 'false',
                                                      rows: this.perPage, page: this.page, type: ['GetLogs'],
                                                      token: this.$store.state.authentication.user.token})
        .then(response => {

          this.page +=this.perPage;
          this.items = this.items.concat(response.data);
          if (response.data.length<this.perPage) {
            $state.loaded();
            $state.complete();
          }
          else {
            $state.loaded();
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
              // auto logout if 401 response returned from api
              this.$store.dispatch('authentication/logout');
              this.$router.push('/')
          } else alert(error)
        })
    },
    selectLists () {
      if (this.type) {
        axios
            .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                          partnertype: this.$store.state.authentication.user.partnertype,
                                                          bankId: '',
                                                          type: this.type, status: false,
                                                          token: this.$store.state.authentication.user.token})
            .then(response => {
                    let i = 0;
                    this.selectList1 = response.data.filter(v => v.reqtype === this.selectList.filter(v => v.label === 'select1')[0].type);
                    for ( i = 0; i < this.selectList1.length; i++) {
                      this.selectList1[i].value=this.selectList1[i][this.selectList.filter(v => v.label === 'select1')[0].value]
                      this.selectList1[i].text=this.selectList1[i][this.selectList.filter(v => v.label === 'select1')[0].text];
                    }
                    this.selectList2 = response.data.filter(v => v.reqtype === this.selectList.filter(v => v.label === 'select2')[0].type);
                    for ( i = 0; i < this.selectList2.length; i++) {
                      this.selectList2[i].value=this.selectList2[i][this.selectList.filter(v => v.label === 'select2')[0].value];
                      this.selectList2[i].text=this.selectList2[i][this.selectList.filter(v => v.label === 'select2')[0].text];
                    }
                  })
            .catch(error => {
              if (error.response.status === 401) {
                  // auto logout if 401 response returned from api
                  this.$store.dispatch('authentication/logout');
                  this.$router.push('/')
              } else alert(error)
            })
      }
    },
    tablefields () {
      axios
          .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', type: ['PageConfig'], page: 'logs', partnerType: this.$store.state.authentication.user.partnertype,
                                                        partnerId: this.$store.state.authentication.user.partnerid, partnerUserRole: this.$store.state.authentication.user.role,
                                                        token: this.$store.state.authentication.user.token})
          .then(response => {
                  this.fields=JSON.parse(response.data.filter(v => v.type === 'Table')[0].value);
                  this.selectList=JSON.parse(response.data.filter(v => v.type === 'Select')[0].value)
                  this.type=[];
                  this.firstList=[];
                  this.selectList.forEach((item) => {if (item.type) this.type.push(item.type); if (item.first) this.firstList.push(item.first)})
                })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.dispatch('authentication/logout');
              this.$router.push('/')
            } else alert(error)
          })
    }
  },
  mounted () {
    this.tablefields();
    if (!this.isOwner&&!this.isBank) this.details=false;
  }
}
</script>
<style scoped>

.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
}
</style>
